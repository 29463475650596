.str-chat {
  height: 100%;
}

.str-chat__input-flat {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.str-chat__input-flat .str-chat__textarea>textarea {
  height: 100%;
  background-color: #F3F0EF;
  border-radius: 30px;
  margin: 0 auto;
  width: 100%;
}

.str-chat__input-flat .str-chat__textarea>textarea:focus {
  border: none !important;
  border-radius: 30px;
}

.rta__textarea .str-chat__textarea__textarea .str-chat__message-textarea {
  height: 100%;
}

.str-chat__send-button {
  display: block;
  margin-top: 7px;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: blue;
  border-radius: 50%;
}

.str-chat__send-button>svg {
  width: 20px;
  height: 20px;
  color: white;
}

.str-chat__send-button>svg>path {
  fill: #ffffff;
}

.str-chat__fileupload-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% - 0px);
  left: 25px;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 20px
}

.str-chat__input-flat .str-chat__input-flat-fileupload {
  position: absolute;
  left: 0;
}

.str-chat__input-flat-fileupload {
  width: 40px;
  height: 40px;
}

.str-chat__input-flat-fileupload>svg {
  width: 40px !important;
  height: 40px !important;
}

/* Uncoment if you want to unhide the emoji button */
.str-chat__input-flat .str-chat__input-flat-emojiselect {
  display: none;
}
